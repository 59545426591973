<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<template>
  <div class="main_container">
    <div class="col-12 bread-crumbs">
      <span v-if="user[1] != 'DGIR'">Marchés ></span>
      <span v-else>Lettres de commande ></span>
    </div>
    <div class="row justify-content-center mb-4">
      <div class="col-8">
        <div class="card title-card">
          <div class="card-body text-center title"
               v-if="user[1] != 'DGIR'">
            Liste des marchés
          </div>
          <div class="card-body text-center title"
               v-else>
            Liste des lettres de commande
          </div>
        </div>
      </div>
    </div>
    
    <ul class="nav nav-tabs"
        id="myTab"
        role="tablist"
        v-show="user[1]=='FER'">
      <li class="nav-item">
        <a class="nav-link active"
           id="fer-tab"
           data-toggle="tab"
           href="#fer"
           role="tab"
           aria-controls="fer"
           aria-selected="true"
           @click="reloadListMarche('FER')">FER</a>
      </li>
      <li class="nav-item">
        <a class="nav-link"
           id="dgir-tab"
           data-toggle="tab"
           href="#dgir"
           role="tab"
           ref="DGIR"
           aria-controls="dgir"
           aria-selected="false"
           @click="reloadListMarche('DGIR')">DGIR</a>
      </li>
      <li class="nav-item">
        <a class="nav-link"
           id="ageroute-tab"
           data-toggle="tab"
           href="#ageroute"
           role="tab"
           aria-controls="ageroute"
           ref="AGEROUTE"
           aria-selected="false"
           @click="reloadListMarche('AGEROUTE')">AGEROUTE</a>
      </li>
    </ul>
    <div class="row">
      <Notif :notif="notif" />
    </div>
    
    <div class="tab-content"
         id="myTabContent">
      <div class="table_action">
        <div class="div_left">
          <router-link :to="{ path: '/Marche/add' }"
                       class="btn btn-blue">
            {{user[1]!="DGIR"?"Ajouter un marché":"Ajouter une lettre de commande"}}
          </router-link>
        <!-- <a class="btn_round">Ajouter un décompte presta. intellect.</a> -->
        </div>
        <div class="div_left">
          <router-link :to="{ path: '/filtre' }"
                       class="btn btn-blue">
            Filtrer
          </router-link>
          <!-- <a class="btn_round">Ajouter un décompte presta. intellect.</a> -->
        </div>
        <!-- <div class="div_left">
          <button class="btn btn-blue pl-5 pr-5 op-btn"
                  data-toggle="modal"
                  ref="modal_button"
                  data-target="#filtreModal">Filtrer</button>
        </div> -->
        <div class="div_left"
             v-if="(user[0] == 'ADMIN' && user[1] == 'FER')||(user[1] == 'FER' && user[0] == 'DT')">
          <a class="btn btn-blue px-5"
             data-toggle="modal"
             ref="modal_button"
             data-target="#exampleModal">Importer données historiques</a>
        </div>
        <!-- <div class="div_left">
          <button class="btn btn-blue"
                  @click="downloadExport(marches.url)"
                  download>
            Exporter
          </button>
        </div> -->
      </div>
      
      <!-- BLOC FER -->
      <div class="tab-pane fade py-0"
           :class="user[1]=='FER'?'show active':''"
           id="fer"
           role="tabpanel"
           aria-labelledby="fer-tab">
        <div class="inner_container">
          <div class="tab_holder">
            <table class="table tablestyle_3 table-striped"
                   style="width:4900px;">
              <thead>
                <tr>
                  <th scope="col"
                      class="text-left">
                    Libellé
                  </th>
                  <th scope="col"
                      class="text-left"
                      v-if="user[1] != 'DGIR'">
                    Numéro du marché
                  </th>
                  <th scope="col"
                      class="text-left"
                      v-else>
                    Numéro de lettre de commande
                  </th>
                  <th scope="col"
                      class="text-left">
                    Activité
                  </th>
                  <th scope="col"
                      class="text-left">
                    Objet
                  </th>
                  <th scope="col"
                      class="text-left">
                    Maitre d'ouvrage déléguée
                  </th>
                  <th scope="col"
                      class="text-left">
                    Maitrise d'ouvrage
                  </th>
                  <th scope="col"
                      class="text-left">
                    Mission de suivi de contrôle
                  </th>
                  <!-- <th scope="col"
                      class="text-left">
                    Bureau de contrôle
                  </th> -->
                  <th scope="col"
                      class="text-left">
                    Entreprise
                  </th>
                  <th scope="col"
                      class="text-left">
                    Nature du réseau
                  </th>
                  <th scope="col"
                      class="text-left">
                    Lot
                  </th>
                  <th scope="col"
                      class="text-left">
                    Départements
                  </th>
                  <th scope="col"
                      class="text-left">
                    Référence ANO
                  </th>
                  <th scope="col"
                      class="text-left">
                    Références de l'Ordre de service de démarrage
                  </th>
                  <th scope="col"
                      class="text-left">
                    Références Ordre de service d'arrêt
                  </th>
                  <th scope="col"
                      class="text-left">
                    Références Ordre de service de reprise des travaux
                  </th>
                  <th scope="col"
                      class="text-left">
                    Date de début
                  </th>
                  <th scope="col"
                      class="text-left">
                    Date de fin
                  </th>
                  <th scope="col"
                      class="text-left">
                    Délai d'execution
                  </th>
                  <th scope="col"
                      class="text-left">
                    Date d'approbation'
                  </th>
                  <th scope="col"
                      class="text-left">
                    Date de démarrage effectif
                  </th>
                  <th scope="col"
                      class="text-left">
                    Date de signature de l'autorité contractante
                  </th>
                  <th scope="col"
                      class="text-left">
                    Date de signature de l'attributaire
                  </th>
                  <th scope="col"
                      class="text-left">
                    Date d'établissement du cautionnement AD
                  </th>
                  <th scope="col"
                      class="text-left">
                    Date d'éxpiration du cautionnement AD
                  </th>
                  <th scope="col"
                      class="text-left">
                    Date d'établissement du cautionnement RG
                  </th>
                  <th scope="col"
                      class="text-left">
                    Date d'éxpiration du cautionnement RG
                  </th>
                  <th scope="col"
                      class="text-left">
                    Date d'établissement du cautionnement définitif
                  </th>
                  <th scope="col"
                      class="text-left">
                    Date d'éxpiration du cautionnement définitif
                  </th>
                  <th scope="col"
                      class="text-left">
                    Date de fin des travaux
                  </th>
                  <th scope="col"
                      class="text-left">
                    Date de l'Ordre de service d'arrêt
                  </th>
                  <th scope="col"
                      class="text-left">
                    Date de reprise des travaux selon l'ordre de service 
                  </th>
                  <th scope="col"
                      class="text-left">
                    Domiciliation bancaire
                  </th>
                  <!-- <th scope="col"
                      class="text-left">
                    Montant de l’ordonnancement
                  </th> -->
                  <th scope="col"
                      class="text-right">
                    Montant initial
                  </th>
                  <th scope="col"
                      class="text-right">
                    Montant Total
                  </th>
                </tr>
              </thead>
              <tbody v-if="!loaded">
                <tr>
                  <td class="text-center"
                      colspan="27">
                    Chargement...
                  </td>
                </tr>
              </tbody>
              <tbody v-else-if="long != 0">
                <tr v-for="(marche, key) in marches.donnees"
                    :key="key"
                    @click="launchUpdate(marche.id)"
                    class="text-uppercase">
                  <td style="width: 140px;">
                    {{ marche.libelle }}
                  </td>
                  <td class="text-left"
                      style="width: 140px;">{{ marche.numero_marche }}</td>
                  <td style="width: 140px;"
                      class="text-left">{{ marche.activite.libelle }}</td>
                  <td v-if="marche.objet!=null && marche.objet.length >40"
                      style="width: 140px;"
                      class="text-left">{{ marche.objet.substr(0,40) }}...</td>
                  <td v-else
                      style="width: 140px;"
                      class="text-left">{{ marche.objet}}</td>
                  <td class="text-left" 
                      style="width: 140px;">{{ marche.prestataire.libelle }}</td>
                  <td class="text-left"
                      style="width: 140px;">{{ marche.maitrise_ouvrage_deleguee }}</td>
                  <td class="text-left"
                      style="width: 140px;">{{ marche.mission_suivi_controle }}</td>
                  <!-- <td class="text-left"
                      style="width: 140px;">{{ marche.nom_bureau_controle }}</td> -->
                  <td class="text-left"
                      style="width: 140px;">{{ marche.entreprise }}</td>
                  <td class="text-left"
                      style="width: 140px;">{{ marche.nature_reseau }}</td>
                  <td class="text-left"
                      style="width: 140px;">{{ marche.lot }}</td>
                  <td class="text-left"
                      style="width: 140px;">{{ marche.departement }}</td>
                  <!--<td style="width: 140px;">{{ marche.expiration_caution_AD }}</td> -->
                  <!--<td style="width: 140px;">{{ marche.expiration_caution_RG }}</td> -->
                  <!--<td style="width: 140px;">{{ marche.expiration_cautionnement_definitif }}</td> -->
                  <td class="text-left"
                      style="width: 140px;">{{ marche.rf_ano }}</td>
                  <td style="width: 140px;"
                      class="text-center">{{ marche.references_ordre_service_demarrage }}</td>
                  <td style="width: 140px;"
                      class="text-center">{{ marche.reference_ordre_service_arret }}</td>
                  <td style="width: 140px;"
                      class="text-center">{{ marche.reference_ordre_service_reprise_travaux }}</td>
                  <td class="text-left"
                      style="width: 80px;">{{ marche.date_debut }}</td>
                  <td class="text-left"
                      style="width: 80px;">{{ marche.date_fin }}</td>
                  <td class="text-right"
                      style="width: 80px;">{{ marche.delai_execution_marche }}</td>
                  <td class="text-left"
                      style="width: 140px;">{{ marche.date_approbation_marche }}</td>
                  <td style="width: 140px;"
                      class="text-left">{{ marche.date_demarrage_effectif }}</td>
                  <td style="width: 140px;"
                      class="text-left">{{ marche.date_signature_autorite_contractante }}</td>
                  <td class="text-left"
                      style="width: 140px;">{{ marche.date_sinature_attributaire }}</td>
                  <td class="text-left"
                      style="width: 140px;">{{ marche.date_etablissement_cautionnement_ad }}</td>
                  <td class="text-left"
                      style="width: 140px;">{{ marche.date_expiration_cautionnement_ad }}</td>
                  <td class="text-left"
                      style="width: 140px;">{{ marche.date_etablissement_cautionnement_rg }}</td>
                  <td class="text-left"
                      style="width: 140px;">{{ marche.date_expiration_cautionnement_rg }}</td>
                  <td class="text-left"
                      style="width: 140px;">{{ marche.date_etablissement_cautionnement_definitif }}</td>
                  <td class="text-left"
                      style="width: 140px;">{{ marche.date_expiration_cautionnement_definitif }}</td>
                  <!-- <td style="width: 140px;">{{ marche.annee.annee_ordonnancement }} ({{ marche.annee.annee_ordonnancement }})</td> -->
                  <td class="text-left"
                      style="width: 140px;">{{ marche.date_fin_travaux }} </td>
                  <td class="text-left"
                      style="width: 140px;">{{ marche.date_ordre_service_arret }} </td>
                  <td class="text-left"
                      style="width: 140px;">{{ marche.date_reprise_travaux_selon_ordre_service }} </td>
                  <!-- <td style="width: 140px;">{{ marche.date_expiration_caution_AD }} ({{ marche.date_expiration_caution_AD }})</td> -->
                  <!-- <td style="width: 140px;">{{ marche.date_expiration_cautionnement_rg }} ({{ marche.date_expiration_cautionnement_rg }})</td> -->
                  <!-- <td style="width: 140px;">{{ marche.date_expiration_cautionnement_definitif }} ({{ marche.date_expiration_cautionnement_definitif }})</td> -->
                  <td class="text-left"
                      style="width: 140px;">{{ marche.domiciliation }}</td>
              
                  <!-- <td class="text-right"
                  style="width: 140px;"
                  v-if="isNaN(Math.round(Number(marche.montant_ordonnancement)))">0 FCFA</td>
              <td class="text-right"
                  style="width: 140px;"
                  v-else>{{ Math.round(Number(marche.montant_ordonnancement)).toLocaleString() }} FCFA</td>  -->

                  <td class="text-right"
                      style="width: 140px;"
                      v-if="isNaN(Math.round(Number(marche.montant_initial_marche)))">0 FCFA</td> 
                  <td class="text-right"
                      style="width: 140px;"
                      v-else>{{ Math.round(Number(marche.montant_initial_marche)).toLocaleString() }} FCFA</td> 
                  <td class="text-right"
                      style="width: 140px;"
                      v-if="isNaN(Math.round(Number(marche.montant_total)))">0 FCFA</td>
                  <td class="text-right"
                      style="width: 140px;"
                      v-else>{{ Math.round(Number(marche.montant_total)).toLocaleString() }} FCFA</td>
                </tr>
            
              </tbody>
              <tbody v-else>
                <tr>
                  <td class="text-center"
                      colspan="27">
                    Aucun marché enregistré
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    
      <!-- BLOC DGIR -->
      <div class="tab-pane fade"
           :class="user[1]=='DGIR'?'show active':''"
           id="dgir"
           role="tabpanel"
           aria-labelledby="dgir-tab">
        <div class="inner_container">
          <div class="tab_holder">
            <table class="table tablestyle_3 table-striped"
                   style="width:4900px;">
              <thead>
                <tr>
                  <th scope="col"
                      class="text-left">
                    Libellé
                  </th>
                  <th scope="col"
                      class="text-left"
                      v-if="user[1] != 'DGIR'">
                    Numéro du marché
                  </th>
                  <th scope="col"
                      class="text-left"
                      v-else>
                    Numéro de lettre de commande
                  </th>
                  <th scope="col"
                      class="text-left">
                    Activité
                  </th>
                  <th scope="col"
                      class="text-left">
                    Objet
                  </th>
                  <th scope="col"
                      class="text-left">
                    Maitre d'ouvrage déléguée
                  </th>
                  <th scope="col"
                      class="text-left">
                    Maitrise d'ouvrage
                  </th>
                  <th scope="col"
                      class="text-left">
                    Mission de suivi de contrôle
                  </th>
                  <!-- <th scope="col"
                      class="text-left">
                    Bureau de contrôle
                  </th> -->
                  <th scope="col"
                      class="text-left">
                    Entreprise
                  </th>
                  <th scope="col"
                      class="text-left">
                    Nature du réseau
                  </th>
                  <th scope="col"
                      class="text-left">
                    Lot
                  </th>
                  <th scope="col"
                      class="text-left">
                    Départements
                  </th>
                  <th scope="col"
                      class="text-left">
                    Référence ANO
                  </th>
                  <th scope="col"
                      class="text-left">
                    Références de l'Ordre de service de démarrage
                  </th>
                  <th scope="col"
                      class="text-left">
                    Références Ordre de service d'arrêt
                  </th>
                  <th scope="col"
                      class="text-left">
                    Références Ordre de service de reprise des travaux
                  </th>
                  <th scope="col"
                      class="text-left">
                    Date de début
                  </th>
                  <th scope="col"
                      class="text-left">
                    Date de fin
                  </th>
                  <th scope="col"
                      class="text-left">
                    Délai d'execution
                  </th>
                  <th scope="col"
                      class="text-left">
                    Date d'approbation'
                  </th>
                  <th scope="col"
                      class="text-left">
                    Date de démarrage effectif
                  </th>
                  <th scope="col"
                      class="text-left">
                    Date de signature de l'autorité contractante
                  </th>
                  <th scope="col"
                      class="text-left">
                    Date de signature de l'attributaire
                  </th>
                  <th scope="col"
                      class="text-left">
                    Date d'établissement du cautionnement AD
                  </th>
                  <th scope="col"
                      class="text-left">
                    Date d'éxpiration du cautionnement AD
                  </th>
                  <th scope="col"
                      class="text-left">
                    Date d'établissement du cautionnement RG
                  </th>
                  <th scope="col"
                      class="text-left">
                    Date d'éxpiration du cautionnement RG
                  </th>
                  <th scope="col"
                      class="text-left">
                    Date d'établissement du cautionnement définitif
                  </th>
                  <th scope="col"
                      class="text-left">
                    Date d'éxpiration du cautionnement définitif
                  </th>
                  <th scope="col"
                      class="text-left">
                    Date de fin des travaux
                  </th>
                  <th scope="col"
                      class="text-left">
                    Date de l'Ordre de service d'arrêt
                  </th>
                  <th scope="col"
                      class="text-left">
                    Date de reprise des travaux selon l'ordre de service 
                  </th>
                  <th scope="col"
                      class="text-left">
                    Domiciliation bancaire
                  </th>
                  <!-- <th scope="col"
                      class="text-left">
                    Montant de l’ordonnancement
                  </th> -->
                  <th scope="col"
                      class="text-right">
                    Montant initial
                  </th>
                  <th scope="col"
                      class="text-right">
                    Montant Total
                  </th>
                </tr>
              </thead>
              <tbody v-if="!loaded">
                <tr>
                  <td class="text-center"
                      colspan="27">
                    Chargement...
                  </td>
                </tr>
              </tbody>
              <tbody v-else-if="long != 0">
                <tr v-for="(marche, key) in marches.donnees"
                    :key="key"
                    @click="launchUpdate(marche.id)"
                    class="text-uppercase">
                  <td style="width: 140px;">
                    {{ marche.libelle }}
                  </td>
                  <td class="text-left"
                      style="width: 140px;">{{ marche.numero_marche }}</td>
                  <td style="width: 140px;"
                      class="text-left">{{ marche.activite.libelle }}</td>
                  <td v-if="marche.objet!=null && marche.objet.length >40"
                      style="width: 140px;"
                      class="text-left">{{ marche.objet.substr(0,40) }}...</td>
                  <td v-else
                      style="width: 140px;"
                      class="text-left">{{ marche.objet}}</td>
                  <td class="text-left" 
                      style="width: 140px;">{{ marche.prestataire.libelle }}</td>
                  <td class="text-left"
                      style="width: 140px;">{{ marche.maitrise_ouvrage_deleguee }}</td>
                  <td class="text-left"
                      style="width: 140px;">{{ marche.mission_suivi_controle }}</td>
                  <!-- <td class="text-left"
                      style="width: 140px;">{{ marche.nom_bureau_controle }}</td> -->
                  <td class="text-left"
                      style="width: 140px;">{{ marche.entreprise }}</td>
                  <td class="text-left"
                      style="width: 140px;">{{ marche.nature_reseau }}</td>
                  <td class="text-left"
                      style="width: 140px;">{{ marche.lot }}</td>
                  <td class="text-left"
                      style="width: 140px;">{{ marche.departement }}</td>
                  <!--<td style="width: 140px;">{{ marche.expiration_caution_AD }}</td> -->
                  <!--<td style="width: 140px;">{{ marche.expiration_caution_RG }}</td> -->
                  <!--<td style="width: 140px;">{{ marche.expiration_cautionnement_definitif }}</td> -->
                  <td class="text-left"
                      style="width: 140px;">{{ marche.rf_ano }}</td>
                  <td style="width: 140px;"
                      class="text-center">{{ marche.references_ordre_service_demarrage }}</td>
                  <td style="width: 140px;"
                      class="text-center">{{ marche.reference_ordre_service_arret }}</td>
                  <td style="width: 140px;"
                      class="text-center">{{ marche.reference_ordre_service_reprise_travaux }}</td>
                  <td class="text-left"
                      style="width: 80px;">{{ marche.date_debut }}</td>
                  <td class="text-left"
                      style="width: 80px;">{{ marche.date_fin }}</td>
                  <td class="text-right"
                      style="width: 80px;">{{ marche.delai_execution_marche }}</td>
                  <td class="text-left"
                      style="width: 140px;">{{ marche.date_approbation_marche }}</td>
                  <td style="width: 140px;"
                      class="text-left">{{ marche.date_demarrage_effectif }}</td>
                  <td style="width: 140px;"
                      class="text-left">{{ marche.date_signature_autorite_contractante }}</td>
                  <td class="text-left"
                      style="width: 140px;">{{ marche.date_sinature_attributaire }}</td>
                  <td class="text-left"
                      style="width: 140px;">{{ marche.date_etablissement_cautionnement_ad }}</td>
                  <td class="text-left"
                      style="width: 140px;">{{ marche.date_expiration_cautionnement_ad }}</td>
                  <td class="text-left"
                      style="width: 140px;">{{ marche.date_etablissement_cautionnement_rg }}</td>
                  <td class="text-left"
                      style="width: 140px;">{{ marche.date_expiration_cautionnement_rg }}</td>
                  <td class="text-left"
                      style="width: 140px;">{{ marche.date_etablissement_cautionnement_definitif }}</td>
                  <td class="text-left"
                      style="width: 140px;">{{ marche.date_expiration_cautionnement_definitif }}</td>
                  <!-- <td style="width: 140px;">{{ marche.annee.annee_ordonnancement }} ({{ marche.annee.annee_ordonnancement }})</td> -->
                  <td class="text-left"
                      style="width: 140px;">{{ marche.date_fin_travaux }} </td>
                  <td class="text-left"
                      style="width: 140px;">{{ marche.date_ordre_service_arret }} </td>
                  <td class="text-left"
                      style="width: 140px;">{{ marche.date_reprise_travaux_selon_ordre_service }} </td>
                  <!-- <td style="width: 140px;">{{ marche.date_expiration_caution_AD }} ({{ marche.date_expiration_caution_AD }})</td> -->
                  <!-- <td style="width: 140px;">{{ marche.date_expiration_cautionnement_rg }} ({{ marche.date_expiration_cautionnement_rg }})</td> -->
                  <!-- <td style="width: 140px;">{{ marche.date_expiration_cautionnement_definitif }} ({{ marche.date_expiration_cautionnement_definitif }})</td> -->
                  <td class="text-left"
                      style="width: 140px;">{{ marche.domiciliation}} </td>
              
                  <!-- <td class="text-right"
                  style="width: 140px;"
                  v-if="isNaN(Math.round(Number(marche.montant_ordonnancement)))">0 FCFA</td>
              <td class="text-right"
                  style="width: 140px;"
                  v-else>{{ Math.round(Number(marche.montant_ordonnancement)).toLocaleString() }} FCFA</td>  -->

                  <td class="text-right"
                      style="width: 140px;"
                      v-if="isNaN(Math.round(Number(marche.montant_initial_marche)))">0 FCFA</td> 
                  <td class="text-right"
                      style="width: 140px;"
                      v-else>{{ Math.round(Number(marche.montant_initial_marche)).toLocaleString() }} FCFA</td> 
                  <td class="text-right"
                      style="width: 140px;"
                      v-if="isNaN(Math.round(Number(marche.montant_total)))">0 FCFA</td>
                  <td class="text-right"
                      style="width: 140px;"
                      v-else>{{ Math.round(Number(marche.montant_total)).toLocaleString() }} FCFA</td>
                </tr>
            
              </tbody>
              <tbody v-else>
                <tr>
                  <td class="text-center"
                      colspan="27">
                    Aucun marché enregistré
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- BLOC AGEROUTE -->
      <div class="tab-pane fade"
           :class="user[1]=='AGEROUTE'?'show active':''"
           id="ageroute"
           role="tabpanel"
           aria-labelledby="ageroute-tab">
        <div class="inner_container">
          <div class="tab_holder">
            <table class="table tablestyle_3 table-striped"
                   style="width:4900px;">
              <thead>
                <tr>
                  <th scope="col"
                      class="text-left">
                    Libellé
                  </th>
                  <th scope="col"
                      class="text-left"
                      v-if="user[1] != 'DGIR'">
                    Numéro du marché
                  </th>
                  <th scope="col"
                      class="text-left"
                      v-else>
                    Numéro de lettre de commande
                  </th>
                  <th scope="col"
                      class="text-left">
                    Activité
                  </th>
                  <th scope="col"
                      class="text-left">
                    Objet
                  </th>
                  <th scope="col"
                      class="text-left">
                    Maitre d'ouvrage déléguée
                  </th>
                  <th scope="col"
                      class="text-left">
                    Maitrise d'ouvrage
                  </th>
                  <th scope="col"
                      class="text-left">
                    Mission de suivi de contrôle
                  </th>
                  <!-- <th scope="col"
                      class="text-left">
                    Bureau de contrôle
                  </th> -->
                  <th scope="col"
                      class="text-left">
                    Entreprise
                  </th>
                  <th scope="col"
                      class="text-left">
                    Nature du réseau
                  </th>
                  <th scope="col"
                      class="text-left">
                    Lot
                  </th>
                  <th scope="col"
                      class="text-left">
                    Départements
                  </th>
                  <th scope="col"
                      class="text-left">
                    Référence ANO
                  </th>
                  <th scope="col"
                      class="text-left">
                    Références de l'Ordre de service de démarrage
                  </th>
                  <th scope="col"
                      class="text-left">
                    Références Ordre de service d'arrêt
                  </th>
                  <th scope="col"
                      class="text-left">
                    Références Ordre de service de reprise des travaux
                  </th>
                  <th scope="col"
                      class="text-left">
                    Date de début
                  </th>
                  <th scope="col"
                      class="text-left">
                    Date de fin
                  </th>
                  <th scope="col"
                      class="text-left">
                    Délai d'execution
                  </th>
                  <th scope="col"
                      class="text-left">
                    Date d'approbation'
                  </th>
                  <th scope="col"
                      class="text-left">
                    Date de démarrage effectif
                  </th>
                  <th scope="col"
                      class="text-left">
                    Date de signature de l'autorité contractante
                  </th>
                  <th scope="col"
                      class="text-left">
                    Date de signature de l'attributaire
                  </th>
                  <th scope="col"
                      class="text-left">
                    Date d'établissement du cautionnement AD
                  </th>
                  <th scope="col"
                      class="text-left">
                    Date d'éxpiration du cautionnement AD
                  </th>
                  <th scope="col"
                      class="text-left">
                    Date d'établissement du cautionnement RG
                  </th>
                  <th scope="col"
                      class="text-left">
                    Date d'éxpiration du cautionnement RG
                  </th>
                  <th scope="col"
                      class="text-left">
                    Date d'établissement du cautionnement définitif
                  </th>
                  <th scope="col"
                      class="text-left">
                    Date d'éxpiration du cautionnement définitif
                  </th>
                  <th scope="col"
                      class="text-left">
                    Date de fin des travaux
                  </th>
                  <th scope="col"
                      class="text-left">
                    Date de l'Ordre de service d'arrêt
                  </th>
                  <th scope="col"
                      class="text-left">
                    Date de reprise des travaux selon l'ordre de service 
                  </th>
                  <th scope="col"
                      class="text-left">
                    Domiciliation bancaire
                  </th>
                  <!-- <th scope="col"
                      class="text-left">
                    Montant de l’ordonnancement
                  </th> -->
                  <th scope="col"
                      class="text-right">
                    Montant initial
                  </th>
                  <th scope="col"
                      class="text-right">
                    Montant Total
                  </th>
                </tr>
              </thead>
              <tbody v-if="!loaded">
                <tr>
                  <td class="text-center"
                      colspan="27">
                    Chargement...
                  </td>
                </tr>
              </tbody>
              <tbody v-else-if="long != 0">
                <tr v-for="(marche, key) in marches.donnees"
                    :key="key"
                    @click="launchUpdate(marche.id)"
                    class="text-uppercase">
                  <td style="width: 140px;">
                    {{ marche.libelle }}
                  </td>
                  <td class="text-left"
                      style="width: 140px;">{{ marche.numero_marche }}</td>
                  <td style="width: 140px;"
                      class="text-left">{{ marche.activite.libelle }}</td>
                  <td v-if="marche.objet!=null && marche.objet.length >40"
                      style="width: 140px;"
                      class="text-left">{{ marche.objet.substr(0,40) }}...</td>
                  <td v-else
                      style="width: 140px;"
                      class="text-left">{{ marche.objet}}</td>
                  <td class="text-left" 
                      style="width: 140px;">{{ marche.prestataire.libelle }}</td>
                  <td class="text-left"
                      style="width: 140px;">{{ marche.maitrise_ouvrage_deleguee }}</td>
                  <td class="text-left"
                      style="width: 140px;">{{ marche.mission_suivi_controle }}</td>
                  <!-- <td class="text-left"
                      style="width: 140px;">{{ marche.nom_bureau_controle }}</td> -->
                  <td class="text-left"
                      style="width: 140px;">{{ marche.entreprise }}</td>
                  <td class="text-left"
                      style="width: 140px;">{{ marche.nature_reseau }}</td>
                  <td class="text-left"
                      style="width: 140px;">{{ marche.lot }}</td>
                  <td class="text-left"
                      style="width: 140px;">{{ marche.departement }}</td>
                  <!--<td style="width: 140px;">{{ marche.expiration_caution_AD }}</td> -->
                  <!--<td style="width: 140px;">{{ marche.expiration_caution_RG }}</td> -->
                  <!--<td style="width: 140px;">{{ marche.expiration_cautionnement_definitif }}</td> -->
                  <td class="text-left"
                      style="width: 140px;">{{ marche.rf_ano }}</td>
                  <td style="width: 140px;"
                      class="text-center">{{ marche.references_ordre_service_demarrage }}</td>
                  <td style="width: 140px;"
                      class="text-center">{{ marche.reference_ordre_service_arret }}</td>
                  <td style="width: 140px;"
                      class="text-center">{{ marche.reference_ordre_service_reprise_travaux }}</td>
                  <td class="text-left"
                      style="width: 80px;">{{ marche.date_debut }}</td>
                  <td class="text-left"
                      style="width: 80px;">{{ marche.date_fin }}</td>
                  <td class="text-right"
                      style="width: 80px;">{{ marche.delai_execution_marche }}</td>
                  <td class="text-left"
                      style="width: 140px;">{{ marche.date_approbation_marche }}</td>
                  <td style="width: 140px;"
                      class="text-left">{{ marche.date_demarrage_effectif }}</td>
                  <td style="width: 140px;"
                      class="text-left">{{ marche.date_signature_autorite_contractante }}</td>
                  <td class="text-left"
                      style="width: 140px;">{{ marche.date_sinature_attributaire }}</td>
                  <td class="text-left"
                      style="width: 140px;">{{ marche.date_etablissement_cautionnement_ad }}</td>
                  <td class="text-left"
                      style="width: 140px;">{{ marche.date_expiration_cautionnement_ad }}</td>
                  <td class="text-left"
                      style="width: 140px;">{{ marche.date_etablissement_cautionnement_rg }}</td>
                  <td class="text-left"
                      style="width: 140px;">{{ marche.date_expiration_cautionnement_rg }}</td>
                  <td class="text-left"
                      style="width: 140px;">{{ marche.date_etablissement_cautionnement_definitif }}</td>
                  <td class="text-left"
                      style="width: 140px;">{{ marche.date_expiration_cautionnement_definitif }}</td>
                  <!-- <td style="width: 140px;">{{ marche.annee.annee_ordonnancement }} ({{ marche.annee.annee_ordonnancement }})</td> -->
                  <td class="text-left"
                      style="width: 140px;">{{ marche.date_fin_travaux }} </td>
                  <td class="text-left"
                      style="width: 140px;">{{ marche.date_ordre_service_arret }} </td>
                  <td class="text-left"
                      style="width: 140px;">{{ marche.date_reprise_travaux_selon_ordre_service }} </td>
                  <!-- <td style="width: 140px;">{{ marche.date_expiration_caution_AD }} ({{ marche.date_expiration_caution_AD }})</td> -->
                  <!-- <td style="width: 140px;">{{ marche.date_expiration_cautionnement_rg }} ({{ marche.date_expiration_cautionnement_rg }})</td> -->
                  <!-- <td style="width: 140px;">{{ marche.date_expiration_cautionnement_definitif }} ({{ marche.date_expiration_cautionnement_definitif }})</td> -->
                  <td class="text-left"
                      style="width: 140px;">{{ marche.domiciliation }} </td>
              
                  <!-- <td class="text-right"
                  style="width: 140px;"
                  v-if="isNaN(Math.round(Number(marche.montant_ordonnancement)))">0 FCFA</td>
              <td class="text-right"
                  style="width: 140px;"
                  v-else>{{ Math.round(Number(marche.montant_ordonnancement)).toLocaleString() }} FCFA</td>  -->

                  <td class="text-right"
                      style="width: 140px;"
                      v-if="isNaN(Math.round(Number(marche.montant_initial_marche)))">0 FCFA</td> 
                  <td class="text-right"
                      style="width: 140px;"
                      v-else>{{ Math.round(Number(marche.montant_initial_marche)).toLocaleString() }} FCFA</td> 
                  <td class="text-right"
                      style="width: 140px;"
                      v-if="isNaN(Math.round(Number(marche.montant_total)))">0 FCFA</td>
                  <td class="text-right"
                      style="width: 140px;"
                      v-else>{{ Math.round(Number(marche.montant_total)).toLocaleString() }} FCFA</td>
                </tr>
            
              </tbody>
              <tbody v-else>
                <tr>
                  <td class="text-center"
                      colspan="27">
                    Aucun marché enregistré
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

    </div>
    
    <!-- Pagination -->
    <!-- <div class="col-md-1 mx-auto text-center"
           v-if="pageCount !==0">
        <button @click="previewPage"
                :disabled="pagenumber ==0"
                class="badge badge-pill badge-info">
          &#60;
        </button>
        <button @click="nextPage"
                class="badge badge-pill badge-info">
          &#62;
        </button>
        <br>
        <span>page {{pagenumber+1}}/{{pageCount}}</span>
      </div> -->
    <div class="row pagination">
      <div v-if="marches.pagination"
           class="col-md-2 mx-auto text-center">
        <span v-if="marches.pagination.precedent"
              class="badge badge-pill badge-info"
              @click="pIndex--, paging(marches.pagination.precedent)"> &#60; </span>
        {{ pIndex }}
        <span v-if="marches.pagination.suivant"
              class="badge badge-pill badge-info"
              @click="pIndex++, paging(marches.pagination.suivant)"> &#62; </span>
      </div>
    </div>
 
    <!-- Modal donnée historique-->
    <div class="modal fade"
         id="exampleModal"
         tabindex="-1"
         role="dialog"
         aria-labelledby="exampleModalLabel"
         aria-hidden="true">
      <div class="modal-dialog"
           role="document">
        <div class="modal-content">
          <div class="modal-header">
            <div class="col-11 mx-auto">
              <div class="card title-card">
                <div class="card-body text-center title">
                  Importer données historiques
                </div>
              </div>
            </div>
            <button type="button"
                    class="close"
                    ref="import-close"
                    data-dismiss="modal"
                    aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-row">
              <div class="col-md-11 form-group">
                <label for=""
                >Marché</label
                >
                <div class="custom-file">
                  <label class="custom-file-label">
                    {{ inputFiles.donnees_histo }}
                  </label>
                  <input type="file"
                         name="etat_comptable"
                         class="custom-file-input"
                         id="donnees_histo"
                         ref="donnees_histo"
                         v-on:change="handleFileUpload('donnees_histo')"
                         accept=".xlsx,.csv" />
                  <span v-if="form.errors().has('donnees_histo')"
                        v-text="form.errors().get('donnees_histo')"
                        class="errorMsg"> </span>
                </div>
              </div>
              <div class="form-group col-md-1 pt-2 my-auto">
                <Loader v-show="loading"/>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button"
                    class="btn btn-f-blue"
                    @click="templateDownload('MarcheFinal.xlsx')">Télécharger un template</button>
            <button type="button"
                    class="btn btn-f-blue"
                    data-dismiss="modal">Annuler</button>
            <button type="button"
                    class="btn btn-f-blue"
                    :disabled="url == ''"
                    @click="importer()">Importer</button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal filtre-->
    <!-- <div class="modal fade col-md-12"
         id="filtreModal"
         tabindex="-1"
         role="dialog"
         aria-labelledby="filtreModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-lg"
           role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title"
                id="filtreModalLabel">Paramètres de filtre</h5>
            <button type="button"
                    class="close"
                    ref="btn-close"
                    data-dismiss="modal"
                    aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <ListFilter :champs="chmp"
                        :module="'marches'"/>
          </div>
          <div class="modal-footer">
            <button type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal">Annuler</button>
            <button type="button"
                    class="btn btn-primary"
                    @click="filtrer()">filtrer</button>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>
<style>
@import "./marches.css";
/* li.nav-item a.nav-link{
  font-size: 0.7em;
}

table.table.tablestyle_3.table-striped tbody td,
table.table.tablestyle_3.table-striped thead th{
  font-size: 0.48em;
} */

table.table.tablestyle_3.table-striped{
  width: 250%;
}

</style>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex"
import form from "vuejs-form"
import utils from "@/assets/js/utils"
import Notif from "@/components/shared/Toast"
import Loader from "@/components/shared/Spinner"
// import ListFilter from "@/components/shared/FilterNew"

export default {
  name: "Marches",
  components:{
    Loader,
    // ListFilter,
    Notif
  },
  data: () => ({
    programmeData: {
      libelle: "",
      order: "",
      tri: ""
    },
    notif: {
      activated: "",
      type: "",
      message: ""
    },
    validateData: {
      libelle: null,
      order: "asc",
      tri: null,
      en_attente: "FER",
      filtre:""
    },
    filtre:[],
    proprietes:[],
    chmp:[
      {
        libelle:"libelle",
        valeur:"libelle",
        type:"caractere",
        table:"marches",
      },
      {
        libelle:"Objet",
        valeur:"objet",
        type:"caractere",
        table:"marches",
      },
      {
        libelle:"Bureau de contrôle",
        valeur:"nom_bureau_controle",
        type:"caractere",
        table:"marches",
      },
      {
        libelle:"Référence ANO",
        valeur:"rf_ano",
        type:"caractere",
        table:"marches",
      },
      {
        libelle:"Lot",
        valeur:"lot",
        type:"caractere",
        table:"marches",
      },
      {
        libelle:"numero marche",
        valeur:"numero_marche",
        type:"caractere",
        table:"marches",
      },
      {
        libelle:"Activité",
        valeur:"libelle",
        type:"caractere",
        table:"activites",
      },
      {
        libelle:"Maitre d'ouvrage",
        valeur:"libelle",
        type:"caractere",
        table:"prestataires",
      },
      {
        libelle:"nature du réseau",
        valeur:"nature_reseau",
        type:"caractere",
        table:"marches",
      },
      {
        libelle:"montant initial",
        valeur:"montant_initial_marche",
        type:"numeric",
        table:"marches",
      },
      {
        libelle:"Date de début",
        valeur:"date_debut",
        type:"date",
        table:"marches",
      },
      {
        libelle:"Date de fin",
        valeur:"date_fin",
        type:"date",
        table:"marches",
      },
      {
        libelle:"Délai d'execution",
        valeur:"delai_execution",
        type:"numeric",
        table:"marches",
      },
      {
        libelle:"Date d'approbation",
        valeur:"date_approbation_marche",
        type:"date",
        table:"marches",
      },
      {
        libelle:"Date de démarrage effectif",
        valeur:"date_demarrage_effectif",
        type:"date",
        table:"marches",
      },
      {
        libelle:"Date de signature de l'autorité contractante",
        valeur:"date_signature_autorite_contractante",
        type:"date",
        table:"marches",
      },
      {
        libelle:"Date de signature de l'attributaire",
        valeur:"date_sinature_attributaire",
        type:"date",
        table:"marches",
      },
      {
        libelle:"Date d'établissement du cautionnement AD",
        valeur:"date_etablissement_cautionnement_ad",
        type:"date",
        table:"marches",
      },
      {
        libelle:"Date d'expiration du cautionnement AD",
        valeur:"date_expiration_cautionnement_ad",
        type:"date",
        table:"marches",
      },
      {
        libelle:"Date d'établissement du cautionnement RG",
        valeur:"date_etablissement_cautionnement_rg",
        type:"date",
        table:"marches",
      },
      {
        libelle:"Date d'expiration du cautionnement RG",
        valeur:"date_expiration_cautionnement_rg",
        type:"date",
        table:"marches",
      },
      {
        libelle:"Date d'établissement du cautionnement définitif",
        valeur:"date_etablissement_cautionnement_definitif",
        type:"date",
        table:"marches",
      },
      {
        libelle:"Date d'expiration du cautionnement définitif",
        valeur:"date_expiration_cautionnement_definitif",
        type:"date",
        table:"marches",
      },
    ],
    user: "",
    maitrise_ouvrage_deleguee : "",
    baseUrl: process.env.VUE_APP_UPLOAD,
    pIndex: 1,
    long: "",
    listExtensionFiles:"",
    loaded: false,
    loading:false,
    url:"",
    pagenumber:0,
    size:15,
    inputFiles: {  
      donnees_histo:"" 
    },
    form: form({
      donnees_histo:"" 
    })
      .rules({
        donnees_histo:"required" 
      })
      .messages({
        donnees_histo:"Ce champ est requis" 
      }),
    goodUrl: "",
    libelle:"FER"
  }),
  watch: {
    marches() {
      if (this.marches.donnees) {
        this.loaded = true
        this.long = this.marches.donnees.length
        this.marches.donnees.forEach(marche => {
          var nouveau_departement=""
          for (let index = 0; index < marche.departement.length; index++) {
            if (index<marche.departement.length && index+1<marche.departement.length) {
              nouveau_departement+=marche.departement[index].libelle+" - "    
            }else{
              nouveau_departement+=marche.departement[index].libelle    
            }
          }
          marche.departement=nouveau_departement
        })
        this.$refs["btn-close"].click()
      }
    },
    msgSuccessImport(){
      if (this.msgSuccessImport) {
        this.notif.message = this.msgSuccessImport
        this.notif.type = "toast-success"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.form[this.uploadfile]=""
            this.inputFiles.donnees_histo=""
            this.setMarches("")
            this.setMsgSuccessImport("")
            this.Marches(null)
            this.$refs["import-close"].click()
            this.$refs["donnees_histo"].value=""
          }.bind(this),
          3000
        )
      }
    },
    files() {
      if (this.files && this.files["url"]) {
        this.form[this.uploadfile] = process.env.VUE_APP_UPLOAD + this.files["url"]
        this.url = this.form[this.uploadfile]
        this.loading=false
      }
    },
    msgFailImport(){
      if (this.msgFailImport) {
        this.notif.message = this.msgFailImport
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.setFiles("")
            this.form[this.uploadfile]=""
            this.inputFiles.donnees_histo=""
            this.$refs["donnees_histo"].value=""
            this.notif.activated = ""
            this.setMsgFailImport("")
          }.bind(this),
          3000
        )
      }
    },
  },
  created() {
    localStorage.setItem("module","marches")
    const userdetails = JSON.parse(localStorage.getItem("user"))
    this.user = userdetails.user_role.split("_")
    console.log(this.$refs)
    if (this.user[1]!='FER') {
      this.Marches(null)
    }else{
      this.Marches({filtres:this.filtre,proprietes:this.proprietes,libelle:this.libelle})
    }
    this.listExtensionFiles=utils.formaFichierAccepte
  },
  computed: {
    ...mapGetters(["marches","files", "errors","msgSuccessImport","msgFailImport"]),
    // pageCount(){
    //   if(this.marches.donnees !== undefined){
    //     var l = this.marches.donnees.length
    //     var s = this.size
    //   }
    //   return Math.ceil(l/s)
    // },
    // listDonneeMarche(){
    //   const start = this.pagenumber * this.size,
    //         end = start + this.size
    //   return this.marches.donnees.slice(start,end)
    // },
  },
  methods: {
    ...mapActions(["Marches","saveFile", "pageMarches","importMarche"]),
    ...mapMutations(["setMarches","setMsgSuccessImport","setMsgFailImport","setFiles","setErors"]),
    onFormChange() {
      this.form.validate()
    },
    launchUpdate(id) {
      this.$router.push({ name: "Detail", params: { id: id } })
    },
    filtrer() {
      this.loaded = false
      this.long = null
      this.setMarches("")
      this.Marches({filtres:this.filtre,proprietes:this.proprietes,libelle:this.libelle})
    },
    restoring() {
      this.loaded = false
      this.long = null
      this.setMarches("")
      this.Marches(null)
      this.programmeData = { libelle: "", order: "", tri: "" }
    },
    handleFileUpload(filename) {
      this.inputFiles[filename] = this.$refs[filename].files[0].name
      // this.uploadfile = filename
      const extension = this.inputFiles[filename].split(".")[1]

      if (this.listExtensionFiles.includes(extension)) {
        // this.loading[filename] = true
        // this.disabled = true
        this.loading= true
        this.uploadfile = filename
        this.saveFile(this.$refs[filename].files[0])
      } else {
        this.inputFiles[filename] = ""
        this.notif.message = "Mauvais format du fichier !!"
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
          }.bind(this),
          5000
        )
      }
      // this.selectedFile = this.$refs[filename].files[0]
      // this.saveFile(this.$refs[filename].files[0])
    },
    importer() {
      this.importMarche({url:this.url})
    },
    paging(url) {
      if (this.pIndex == 0) {
        this.pIndex = 1
      }
      // console.log(this.url)
      this.goodUrl = url.split("/")
      url = this.goodUrl[1]
      this.loaded = false
      this.long = null
      var count=1
      var used=0
      url+="&filtres=["  
      this.filtre.forEach(filtre => {
        if (filtre.type == "date") {
          if (filtre.operation!="" && filtre.valeur!="") {
            if (filtre.operation == "interval" && filtre.valeur!="" && filtre.valeur1!="") {
              if (count>1 && used !=0) {
                url+=","
              }
              url+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","operation":"'
                +filtre.operation+'","valeur":"'+filtre.valeur+'","valeur1":"'
                +filtre.valeur1+'","periode":"'+filtre.periode+'","type":"'
                +filtre.type+'"}'
              used+=1
            }else{
              if (count>1 && used !=0) {
                url+=","
              }
              url+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","operation":"'
                +filtre.operation+'","valeur":"'+filtre.valeur+'","periode":"'+filtre.periode+'","type":"'
                +filtre.type+'"}'
              used+=1
            }
          }
        }else{
          if (filtre.valeur!="" && filtre.nature!="") {
            if (count>1 && used !=0) {
              url+=","
              console.log(count)
            }
            url+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","nature":"'+filtre.nature+'","valeur":"'+filtre.valeur+'","type":"'+filtre.type+'"}'
            used+=1
          }
        }
        count+=1
      })
      url+="]"
      this.setMarches("")
      this.pageMarches(url)
    },
    templateDownload(file){
      const link = document.createElement('a')
      link.href = process.env.VUE_APP_APIURL+"files/excel/FichiersModel/"+file
      link.download = file
      link.click()
      URL.revokeObjectURL(link.href)
    },
    downloadExport(file){
      const link = document.createElement('a')
      link.href = process.env.VUE_APP_UPLOAD+file
      link.download = file
      link.click()
      URL.revokeObjectURL(link.href)
    },
    reloadListMarche(key){
      if (this.user[1] == 'FER') {
        this.libelle=""
        this.libelle=key
        this.Marches({filtres:this.filtre,proprietes:this.proprietes,libelle:this.libelle})
      }
    }
    // nextPage(){
    //   if(this.pagenumber +1 < this.pageCount){
    //     this.pagenumber++
    //   }
    // },
    // previewPage(){
    //   this.pagenumber--
    // },
  }
}
</script>
